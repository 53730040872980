<template lang="pug">
.brand-agency-impersonate-floating.sticky-top(v-if="!hideStickyBar")
  div(v-html="$t(affiliateImpersonate ? 'loggedInAsAffiliate' : 'loggedInAs', { accountName })")
  .brand-link(@click="backToAgency") &nbsp;{{ $t('backToAgency') }}
</template>
<script>
  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import { mapState } from 'vuex';
  import { setAccountIdInCookie } from '../util';

  export default {
    computed: {
      ...mapState(['account', 'affiliateImpersonate', 'hideStickyBar']),

      accountName() {
        if (this.account.name) {
          return this.account.name.length > 30
            ? `${this.account.name.substring(0, 30)}...`
            : this.account.name;
        }
        return '';
      },
    },

    methods: {
      async backToAgency() {
        this.$store.commit('hideStickyBar', true);
        this.$router.replace({ name: 'account-switching' });

        const {
          data: { login },
        } = await this.$apollo.mutate({
          mutation: CREATE_LOGIN_CREDENTIALS,
        });

        const userId = login.dataAccountId;
        setAccountIdInCookie(userId);
        this.$store
          .dispatch('login', {
            expiration: login.expiration,
            accountType: login.accountType,
            locale: login.locale,
            affiliateImpersonate: false,
          })
          .then(() => {
            this.$router.replace({ name: 'agency_dashboard', params: { userId } });
            this.$store.commit('hideStickyBar', false);
          });
      },
    },
  };
</script>

<style lang="sass">
  .brand-agency-impersonate-floating
    z-index: 200002!important
    padding: 1rem
    font-size: 0.875rem
    display: flex
    align-items: center
    justify-content: center
    background: white
    box-shadow: 0 .3125rem .9375rem rgba(0,0,0,.05)
</style>
